import { Link } from "react-router-dom";
import AboutImg from "../assets/images/about.svg";
import Elipses from "../assets/images/elipses.png";
import Mission from "../assets/images/mission-1.png";

export const About = () => {
    return (
        <>
            <div className="bg-[#F9FEFF] mt-20 md:pt-20" id="about">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex flex-col-reverse gap-10  md:flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                    <div className="lg:w-1/2">
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center md:w-[50%] md:mx-auto lg:mr-auto lg:ml-0 lg:mr-0 lg:text-left mb-8 ">
                            About  <span className="text-[#09A5B6]">LTC Home  Care</span>

                        </h2>

                        <p className="text-sm leading-7 lg:w-[80%] text-[#262626] text-center font-[400] lg:text-left">
                            We are a residential and nursing home care service right at your doorstep. We offer full range of skilled and non-skilled services. Which includes any professional services that allows clients to live safely in their homes.                             </p>

                        <div className="mt-8 flex items-center justify-center lg:justify-start gap-5">
                            <a className="bg-[#09A5B6] rounded-[16px] px-8 py-3.5 text-white text-sm font-[500]" href="mailto:ltchomeservices24@gmail.com">Book Appointment</a>

                        </div>

                    </div>

                    <div className="w-full md:w-1/2  relative">

                        <div className="">


                            <img src={AboutImg} alt="Hero" className="lg:w-[100%]  my-10 md:my-0  ml-auto" />
                        </div>
                    </div>

                    <div>

                    </div>
                </div>
            </div>
            <div id="services" className="mx-auto w-full max-w-screen-xl p-4 md:py-10 md:pb-0 flex flex-col lg:flex-row items-start justify-between flex-shrink-0 text-[#292D32] h-full">
                <div className="w-full">

                    <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center mb-1">
                        Services We Provide

                    </h2>
                </div>
            </div>
            <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-5">
                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">24/7 Care</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">We provide you with comprehensive 24/7 services </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Skilled Nursing</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">Our are nurses are professionals and highly skilled at their jobs </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Home Health Aides</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">We provide you with competent home health aides. </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Medication Reminders</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">We make sure to remind you of your medications daily </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Meal Preparation</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">Meal preparations are also available </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Personal Care</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">We provide personal care </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Housekeeping Errands</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">Housekeeping errands are made available for all individuals </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Transportations</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">We provide you with transportation </p>
                </div>

                <div className="bg-[#09A5B61A] p-5 rounded-md">
                    <h5 className="text-xl font-[500] mt-2">Sitter Services</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[400]">Sitter services are made readily available.</p>
                </div>



            </div>
            <section className="bg-[#F9FEFF] py-5 mt-10 md:mt-0">
                <div className="mx-auto w-full max-w-screen-xl p-4 md:py-10 flex  text-[#292D32] h-full grid grid-cols-1  md:gap-10">


                    <div>
                        <h2 className="text-2xl md:text-3xl font-[700] text-[#262626] text-center  mb-1">
                            Our Vision
                        </h2>

                        <p className="my-2 text-sm lg:w-1080%] text-[#292D32]  font-[500] md:w-2/3 text-center mt-4 mx-auto">
                            LTC strives to improve the quality of care for those we serve through the delivery of clinical excellence, extraordinary service and compassionate care. We offer a safe compassionate environment for each person is entrusted into our care.
                        </p>
                    </div>




                </div>
            </section>
        </>
    )
}