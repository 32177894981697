import { Link } from "react-router-dom";
import HeroImg from "../assets/images/hero.svg";
import users from "../assets/images/users.svg";

export const Hero = () => {
    return (
        <nav className="bg-[#fff]  relative top-[70px] pt-10 lg:pt-0 md:top-[80px] lg:top-[140px] lg:pb-[0px]" id="home">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                <div className="lg:w-[1/2]">
                    <h2 className="text-4xl md:text-5xl font-[700] text-[#262626] text-center lg:text-left mb-3">
                        <span className="text-[#09A5B6]">Home care</span> services

                    </h2>
                    <h2 className="text-4xl md:text-5xl font-[700] text-[#262626] text-center lg:text-left">
                        right at your  <span className="text-[#09A5B6]">doorstep.</span>

                    </h2>

                    <p className="my-8  text-md lg:w-[60%] text-[#292D32] text-center lg:text-left font-[500]">
                        We offer a full range of skilled and non skilled services that allow clients to live safely in their homes.                     </p>

                    <div className="my-8 flex items-center justify-center lg:justify-start gap-">


                        <a className="bg-[#09A5B6] rounded-md px-8 py-3.5 text-white text-sm font-[500]" href="tel:+(256)5353115">Contact Us</a>
                        <a className="text-[#09A5B6] rounded-md px-8 py-3.5 text-md font-[500] underline" href="#about">Learn more</a>
                    </div>
                </div>

                <div className="w-full lg:w-1/2">
                    <img src={HeroImg} alt="Hero" className="lg:w-[100%] mx-auto lg:ml-auto" />
                </div>

                <div>

                </div>
            </div>
        </nav>
    )
}