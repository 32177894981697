import Logo from "../assets/images/logo-app.svg";
import Call from "../assets/images/call-outgoing.svg";
import Location from "../assets/images/location.svg";
import Mail from "../assets/images/mark_email_unread.svg";


export const Footer = () => {
    return (

        <footer className="py-8 md:py-0 bg-[#222222]">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-3">




                    <div className="py-0 lg:py-16 lg:pe-16">
                        <div className="text-teal-600 lg:block">
                            <div className="block text-teal-600 w-24">
                                <img src={Logo} alt="Footer" className="w-[50px] md:w-[80px]" />
                            </div>
                        </div>

                        <div className="mt-8 w-full">
                            <div>
                                <p className="font-medium text-white text-sm">
                                    LTC strives to improve the quality for those we serve through the delivery of clinical excellence, extraordinary service and compassionate care. We offer a safe compassionate environment for each person is entrusted into our care.                                    </p>


                            </div>
                        </div>
                    </div>



                    <div className="py-0 lg:py-16 lg:pe-16">

                        <div className="mt-8 md:w-fit mx-auto">


                            <div>
                                <p className="font-medium text-[#fff] font-semibold">Company</p>

                                <ul className="mt-3 space-y-3 text-sm">
                                    <li>
                                        <a href="#about" className="text-sm font-[500] text-[#fff]">
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#services" className="text-sm font-[500] text-[#fff]">
                                            Our Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#approach" className="text-sm font-[500] text-[#fff]">
                                            Our Story
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#choose" className="text-sm font-[500] text-[#fff]">
                                            Why Choose us
                                        </a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="py-0 lg:py-16 lg:pe-16">

                        <div className="mt-8 md:w-fit mx-auto">


                            <div>
                                <p className="font-medium text-[#fff] font-semibold">Contact Us</p>

                                <ul className="mt-3 space-y-3 text-sm">
                                    <li className="flex items-center gap-2">
                                        <img src={Call} alt="call" />


                                        <a href="tel:+(256)5353115" className="text-sm font-[500] text-[#fff]">
                                            (256)5353115
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Location} alt="call" />


                                        <a href="tel:+(256)5353115" className="text-sm font-[500] text-[#fff]">
                                            Nashville (615) 280-7092. Antioch TN 37013
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Location} alt="call" />


                                        <a href="tel:+(256)5353115" className="text-sm font-[500] text-[#fff]">
                                            327 Saddlegate Dr huntsville. AL35810
                                            Ph.256 535-3116
                                        </a>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <img src={Mail} alt="call" />


                                        <a href="mailto:ltchomeservices24@gmail.com" className="text-sm font-[500] text-[#fff]">
                                            ltchomeservices24@gmail.com
                                        </a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}