import { Link } from "react-router-dom";
import PlayImg from "../assets/images/play.png";
import Elipses from "../assets/images/elipses.png";
import BG from "../assets/images/bg.png";


export const Approach = () => {
    return (
        <>
            <div className="bg-[#fff] lg:py-20   min-h-[220px]  md:min-h-[90svh] flex items-center justify-center" id="approach">
                <div className="mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full flex items-center justify-center">

                    {/* <div className="l">
                        <h2 className="text-2xl md:text-3xl font-semibold text-[#fff] text-center mb-3">
                            #Our Story
                        </h2>


                        <img src={PlayImg} alt="play" className="w-[40%] mx-auto" />

                    </div> */}
                    <div>

                    </div>
                </div>
            </div>


            <div id="choose" className="mx-auto h-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full relative">

                {/* <div className="absolute left-0 w-full mt-4">
                    <img src={BG} alt="play" className="w-[30%] mx-auto" />
                </div> */}

                <div className="text-center mt-5">
                    <div className="w-fit mx-auto bg-[#DCDCDC33] text-md font-[600] p-3 px-5 rounded-2xl">
                        Why Choose Us
                    </div>
                    <h5 className="text-xl lg:text-2xl font-semibold mt-5"> Why  Should You Choose LTC</h5>
                    <p className="mt-3 md:w-2/3 mx-auto text-center leading-7 text-sm font-[400]">LTC residential and nursing care services is dedicated to give you comprehensive healthcare solutions at home ranging from adolescent to geriatric care. We provide our residents with 24/7  care, we offer a safe and compassionate environment to each person entrusted into our care.</p>
                </div>

            </div>

            <div className="hidden mx-auto w-full max-w-screen-xl p-4 md:pb-10 flex  text-[#292D32] h-full grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-10">
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Onsite House Manager</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our house manager stays in the home to ensure rules are observed.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Re-Creation Center</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our recreation center is well furnished and equipped recreation room to keep you busy.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Fully Furnished</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Angel Of Royalty Home Care is 100% furnished with the modern interior to suite everyone</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">24/7 Surveillance</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Home Care is under 24/7 surveillance making our home safe and secure.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Wi-Fi Services</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our Care home  has 24/7 wi-fi services  installed for you to browse the internet.</p>
                </div>
                <div className="bg-[#DCDCDC33] p-5 rounded-2xl">
                    <img src={Elipses} alt="elipses" className="w-[8%]" />
                    <h5 className="text-md font-semibold mt-2">Weekly Group Events</h5>
                    <p className="mt-2 text-sm lg:w-[80%] text-[#292D32] text-left lg:text-left font-[500]">Our weekly group events consists of movie nights, picnics, arts and crafts and pizza party.</p>
                </div>


            </div>

            <div id="consulting" className="mx-auto w-full max-w-screen-xl p-5 md:py-10  bg-[#09A5B6] rounded-2xl w-[90%] md:w-2/3 mx-auto mb-10 text-[#fff] h-full ">
                <h5 className="text-md lg:text-2xl font-semibold mt-5 text-center w-full">We provide you with exceptional In-home care services</h5>
                <p className="my-6 text-sm lg:w-[100%] text-[#fff] text-center lg:text-center font-[500]">
                    At LTC In-home care services, We provide you with the absolute best home care services
                </p>

                <form className="my-6 w-full md:w-max mx-auto">
                    <label htmlFor="UserEmail" className="sr-only"> Email </label>
                    <a className="block mt-1 w-full rounded-md bg-[#fff] px-10 py-3  w-full text-sm font-bold tracking-wide text-[#09A5B6] text-center transition-none  sm:mt-0 sm:w-auto sm:shrink-0"
                        href="tel:+(256)5353115">Contact Us</a>
                </form>
            </div>
        </>
    )
}