import { Link } from "react-router-dom";
import Logo from "../assets/images/logo-app.svg";

export const Header = () => {
    return (
        <nav className="bg-[#292D32] py-2 bg-rd-500 fixed top-0 w-full h-max z-50 shadow-sm md:shadow-0">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex  items-center justify-between flex-shrink-0 text-white">
                <div>
                    <Link to={"/"}>
                        <img src={Logo} alt="" className="w-[50px] md:w-[70px]" style={{ width: '70px' }} />
                    </Link>
                </div>

                <div className="hidden lg:flex text-[#292D32] flex gap-10 items-center">
                    <a href="#home" className="text-sm font-[500] text-[#fff]">Home</a>
                    <a href="#services" className="text-sm font-[500] text-[#fff]">Services</a>
                    <a href="#about" className="text-sm font-[500] text-[#fff]">About us</a>
                    <a href="#consulting" className="text-sm font-[500] text-[#fff]">Consulting</a>
                </div>

                <div>
                    <div className="lg:flex text-[#292D32] flex gap-10 items-center">
                        <a className="bg-[#09A5B6] rounded-md px-5 py-2.5 text-white text-sm font-[500]" href="tel:+(256)5353115">Contact Us</a>
                    </div>

                </div>
            </div>
        </nav>
    )
}